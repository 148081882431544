var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "Horizontal form label sizing" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codeHorizontalSize) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c("b-card-text", [
        _c("span", [
          _vm._v(
            "You can control the label text size match the size of your form input(s) via the optional "
          )
        ]),
        _c("code", [_vm._v("label-size")]),
        _c("span", [_vm._v(" prop. Values can be ")]),
        _c("code", [_vm._v("'sm'")]),
        _c("span", [_vm._v(" or ")]),
        _c("code", [_vm._v("'lg'")]),
        _c("span", [
          _vm._v(
            " for small or large label, respectively. Sizes work for both horizontal and non-horizontal form groups."
          )
        ])
      ]),
      _c(
        "b-form-group",
        {
          attrs: {
            "label-cols": "4",
            "label-cols-lg": "2",
            "label-size": "lg",
            label: "Large",
            "label-for": "input-lg"
          }
        },
        [
          _c("b-form-input", {
            attrs: { id: "input-lg", size: "lg", placeholder: "Large Input" }
          })
        ],
        1
      ),
      _c(
        "b-form-group",
        {
          attrs: {
            "label-cols": "4",
            "label-cols-lg": "2",
            label: "Default",
            "label-for": "input-default"
          }
        },
        [
          _c("b-form-input", {
            attrs: { id: "input-default", placeholder: "Normal Input" }
          })
        ],
        1
      ),
      _c(
        "b-form-group",
        {
          attrs: {
            "label-cols": "4",
            "label-cols-lg": "2",
            "label-size": "sm",
            label: "Small",
            "label-for": "input-sm"
          }
        },
        [
          _c("b-form-input", {
            attrs: { id: "input-sm", size: "sm", placeholder: "Small Input" }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }