var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "Input Validation States with Tootltips" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codeValidateTooltips) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c("b-card-text", [
        _c("span", [_vm._v("Use ")]),
        _c("code", [_vm._v("tooltip")]),
        _c("span", [_vm._v(" true in ")]),
        _c("code", [_vm._v("<b-form-valid-feedback>")]),
        _c("span", [_vm._v(" and ")]),
        _c("code", [_vm._v("<b-form-invalid-feedback>")]),
        _c("span", [_vm._v(" form sub-components.")])
      ]),
      _c(
        "b-form",
        {
          staticClass: "needs-validation",
          attrs: { novalidate: "" },
          on: {
            submit: function($event) {
              $event.preventDefault()
            }
          }
        },
        [
          _c(
            "b-form-row",
            [
              _c(
                "b-col",
                { staticClass: "mb-3", attrs: { md: "4" } },
                [
                  _c("label", { attrs: { for: "input-valid1" } }, [
                    _vm._v("Valid Input:")
                  ]),
                  _c("b-form-input", {
                    attrs: {
                      id: "input-valid1",
                      state: _vm.value1.length > 0,
                      placeholder: "Valid input"
                    },
                    model: {
                      value: _vm.value1,
                      callback: function($$v) {
                        _vm.value1 = $$v
                      },
                      expression: "value1"
                    }
                  }),
                  _c("b-form-valid-feedback", { attrs: { tooltip: "" } }, [
                    _vm._v(" Looks good! ")
                  ]),
                  _c("b-form-invalid-feedback", { attrs: { tooltip: "" } }, [
                    _vm._v(" Please provide a valid input. ")
                  ])
                ],
                1
              ),
              _c(
                "b-col",
                { staticClass: "mb-3", attrs: { md: "4" } },
                [
                  _c("label", { attrs: { for: "input-invalid1" } }, [
                    _vm._v("Invalid Input:")
                  ]),
                  _c("b-form-input", {
                    attrs: {
                      id: "input-invalid1",
                      state: _vm.value2.length > 0,
                      placeholder: "Invalid input"
                    },
                    model: {
                      value: _vm.value2,
                      callback: function($$v) {
                        _vm.value2 = $$v
                      },
                      expression: "value2"
                    }
                  }),
                  _c("b-form-valid-feedback", { attrs: { tooltip: "" } }, [
                    _vm._v(" Looks good! ")
                  ]),
                  _c("b-form-invalid-feedback", { attrs: { tooltip: "" } }, [
                    _vm._v(" Please provide a valid input. ")
                  ])
                ],
                1
              ),
              _c(
                "b-col",
                { staticClass: "mb-3", attrs: { md: "4" } },
                [
                  _c("label", { attrs: { for: "input-invalid2" } }, [
                    _vm._v("Invalid Input:")
                  ]),
                  _c("b-form-input", {
                    attrs: {
                      id: "input-invalid2",
                      state: _vm.value3.length > 0,
                      placeholder: "Invalid input"
                    },
                    model: {
                      value: _vm.value3,
                      callback: function($$v) {
                        _vm.value3 = $$v
                      },
                      expression: "value3"
                    }
                  }),
                  _c("b-form-valid-feedback", { attrs: { tooltip: "" } }, [
                    _vm._v(" Looks good! ")
                  ]),
                  _c("b-form-invalid-feedback", { attrs: { tooltip: "" } }, [
                    _vm._v(" Please provide a valid city. ")
                  ])
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { cols: "12" } },
                [
                  _c(
                    "b-button",
                    {
                      directives: [
                        {
                          name: "ripple",
                          rawName: "v-ripple.400",
                          value: "rgba(255, 255, 255, 0.15)",
                          expression: "'rgba(255, 255, 255, 0.15)'",
                          modifiers: { "400": true }
                        }
                      ],
                      attrs: { type: "submit", variant: "primary" }
                    },
                    [_vm._v(" Submit ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }