var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "Basic Inputs" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codeBasic) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { staticClass: "mb-1", attrs: { md: "6", xl: "4" } },
            [
              _c(
                "b-form-group",
                { attrs: { label: "Basic Input", "label-for": "basicInput" } },
                [
                  _c("b-form-input", {
                    attrs: { id: "basicInput", placeholder: "Enter Email" }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-col",
            { staticClass: "mb-1", attrs: { md: "6", xl: "4" } },
            [
              _c(
                "b-form-group",
                [
                  _c("label", { attrs: { for: "InputHelp" } }, [
                    _vm._v("Input text with help")
                  ]),
                  _c("small", { staticClass: "text-muted" }, [
                    _vm._v(" eg. "),
                    _c("i", [_vm._v("someone@example.com")])
                  ]),
                  _c("b-form-input", { attrs: { id: "InputHelp" } })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-col",
            { staticClass: "mb-1", attrs: { md: "6", xl: "4" } },
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: "Disabled Input",
                    "label-for": "disabledInput"
                  }
                },
                [
                  _c("b-form-input", {
                    attrs: { id: "disabledInput", disabled: "" }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { md: "6", xl: "4" } },
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: "With Helper Text",
                    "label-for": "helperInput",
                    description: "Find helper text here for given textbox."
                  }
                },
                [
                  _c("b-form-input", {
                    attrs: { id: "helperInput", placeholder: "Name" }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { md: "6", xl: "4" } },
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: "Readonly Input",
                    "label-for": "readOnlyInput"
                  }
                },
                [
                  _c("b-form-input", {
                    attrs: {
                      id: "readOnlyInput",
                      value: "You can't update me :P",
                      readonly: ""
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { md: "6", xl: "4" } },
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: "Readonly Static Text",
                    "label-for": "plainTextInput"
                  }
                },
                [
                  _c("b-form-input", {
                    attrs: {
                      id: "plainTextInput",
                      plaintext: "",
                      value: "email@pixinvent.com"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }