var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "Datalist support" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codeDatalist) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c("b-card-text", [
        _c("span", [_vm._v("Datalists are a native HTML tag ")]),
        _c("code", [_vm._v("<datalist>")]),
        _c("span", [_vm._v(" that contains a list of ")]),
        _c("code", [_vm._v("<option>")]),
        _c("span", [
          _vm._v(
            " tags. By assigning an ID to the datalist tag, the list can be references from a text input by adding a "
          )
        ]),
        _c("code", [_vm._v("list")]),
        _c("span", [_vm._v(" attribute.")])
      ]),
      _c(
        "b-form-group",
        { staticClass: "mb-0" },
        [
          _c("b-form-input", { attrs: { list: "my-list-id" } }),
          _c(
            "datalist",
            { attrs: { id: "my-list-id" } },
            [
              _c("option", [_vm._v("Manual Option")]),
              _vm._l(_vm.sizes, function(size) {
                return _c("option", { key: size }, [
                  _vm._v(" " + _vm._s(size) + " ")
                ])
              })
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }